import React from 'react';
import { ThemeProvider } from './src/components/theme-context';
// Highlighting for code blocks
import 'prismjs/themes/prism.css';
// font
import '@fontsource/raleway/400.css';
import '@fontsource/raleway/700.css';

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>{element}</ThemeProvider>
);
